<template>
  <div class="generic-list scroll-container">
    <CategoryTitle :category="category" />

    <!-- <OrdersListFilter class="mb-5" @updateOrdersFilter="updateOrdersFilter" /> -->

    <div class="mt-4 d-flex justify-end">
      <a
        class="font-weight-bold mt-4 text-decoration-underline"
        @click="toggleViewAll"
        ><span class="mr-2">{{
          showAll
            ? $t("orders.button.viewLastOrders")
            : $t("orders.button.viewAllOrders")
        }}</span></a
      >
    </div>

    <OrdersTable
      :modify="true"
      v-if="orders && orders.length > 0"
      :orders="orders"
      @delete="deleteOrder"
      @addAllToCart="addAllToCart"
      @editOrder="editOrder"
    ></OrdersTable>
    <v-pagination
      v-if="showAll && orderPager && orderPager.totPages > 1"
      :value="pageFilter"
      :page="orderPager.selPage"
      :length="orderPager.totPages ? orderPager.totPages : 0"
      :totalVisible="7"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
      class="py-6"
    ></v-pagination>

    <v-card
      light
      outlined
      v-if="orders && orders.length == 0"
      class="pa-2 text-center"
      style="width:100%;"
    >
      <v-card-title class="headline">Nessun ordine trovato</v-card-title>
    </v-card>
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import OrdersTable from "@/components/orders/OrdersTable.vue";
//import OrdersListFilter from "@/components/orders/OrdersListFilter.vue";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Modify",
  data() {
    return {
      filterData: {},
      orders: {},
      orderPager: {},
      showAll: false
      // testOrder: {
      //   isTest: true,
      //   orderId: 171086711111111,
      //   orderStatusId: 10,
      //   orderTypeId: 1,
      //   orderStatusDescr: "Annullato",
      //   addDate: "2024-02-09T09:38:00Z",
      //   riderUserId: "",
      //   user: {
      //     userId: 200330,
      //     login: ""
      //   },
      //   timeslot: {
      //     timeslotId: 150,
      //     name: "20:00",
      //     date: "2024-02-13",
      //     beginTime: "8:00:00 PM",
      //     endTime: "10:00:00 PM"
      //   },
      //   shippingAddress: {
      //     addressId: 148326441,
      //     addressTypeId: 2,
      //     addressType: "home",
      //     deliveryServiceId: 2,
      //     zoneId: 1000034,
      //     active: 1,
      //     deliveryAddressId: 148326441,
      //     shippingAddressId: 148326441,
      //     addressName: "PATRONATO PORTA VENEZIA",
      //     address1: "Via Giuseppe Baretti",
      //     address2: "",
      //     addressNumber: "1",
      //     city: "Milano",
      //     postalcode: "20122",
      //     doorbellName: "",
      //     province: "MI",
      //     apartmentNumber: "2",
      //     maxDistance: 0.4,
      //     latitude: 45.47163949999999,
      //     longitude: 9.2048765
      //   },
      //   deliveryAddress: {
      //     addressId: 148326441,
      //     addressTypeId: 2,
      //     addressType: "home",
      //     deliveryServiceId: 2,
      //     zoneId: 1000034,
      //     active: 1,
      //     deliveryAddressId: 148326441,
      //     shippingAddressId: 148326441,
      //     addressName: "PATRONATO PORTA VENEZIA",
      //     address1: "Via Giuseppe Baretti",
      //     address2: "",
      //     addressNumber: "1",
      //     city: "Milano",
      //     postalcode: "20122",
      //     doorbellName: "",
      //     province: "MI",
      //     apartmentNumber: "2",
      //     maxDistance: 0.4,
      //     latitude: 45.47163949999999,
      //     longitude: 9.2048765
      //   },
      //   homeDelivery: true,
      //   warehouse: {
      //     warehouseId: 43,
      //     name: "Savona"
      //   },
      //   orderInfos: {},
      //   totalPrice: 89.1,
      //   totalPriceVariable: 0,
      //   netTotal: 89.1,
      //   deliveryFee: 0,
      //   packageTotal: 0,
      //   giftTotal: 0,
      //   refundTotal: 0,
      //   taxTotal: 0,
      //   grossTotal: 89.1,
      //   deliveredNetTotal: 89.1,
      //   deliveredGrossTotal: 0,
      //   deliveredGiftTotal: 0,
      //   deliveredRefundTotal: 0,
      //   deliveredPackageTotal: 0,
      //   deliveredDeliveryFee: 0,
      //   paymentTypeId: 8,
      //   isEditable: false,
      //   isDeletable: false,
      //   isParkingEnabled: false,
      //   isPayable: false,
      //   isRefundable: false,
      //   suborders: [
      //     {
      //       suborderId: 149138272,
      //       grossTotal: 89.1,
      //       suborderInfos: {},
      //       suborderItems: [
      //         {
      //           orderItemId: "171018594",
      //           quantity: 9,
      //           weight: 0,
      //           deliveredQuantity: 0,
      //           deliveredWeight: 0,
      //           deliveredGrossTotal: 0,
      //           grossTotal: 89.1,
      //           unitPrice: 9.9,
      //           product: {
      //             available: 0,
      //             productId: 148000002,
      //             codInt: "148000002",
      //             codVar: "1",
      //             code: "148000002",
      //             codeVariant: "1",
      //             name: "Offerta TELEFONIA 1",
      //             shortDescr: "Descrizione breve dell'offerta Telefonia 1",
      //             description: "",
      //             pubState: 1,
      //             ivaCategory: {
      //               ivaCategoryId: 23,
      //               code: "22-N",
      //               descr: "",
      //               ivaPct: "0.22"
      //             },
      //             price: 9.9,
      //             priceId: 45504577,
      //             priceListName: "Home Delivery Assago",
      //             priceListId: 4,
      //             priceDisplay: 9.9,
      //             priceUnitDisplay: "pz",
      //             slug: "148000002_offerta-telefonia-1",
      //             categoryId: -1,
      //             mediaURL:
      //               "https://www.tigros.it/photo2/2020/05/27/0/main/thumb/foto-telefonia_1.png",
      //             mediaURLMedium:
      //               "https://www.tigros.it/photo2/2020/05/27/0/main/photo/foto-telefonia_1.png",
      //             dayLock: "",
      //             vendor: {
      //               vendorId: 0
      //             },
      //             productClasses: [
      //               {
      //                 productClassId: 10536,
      //                 productClassGroupId: 10002,
      //                 name: "OFFERTA DEL MESE",
      //                 description: "2",
      //                 iconSource:
      //                   "/img_layout/product_classes/offerta-mese.svg",
      //                 productClassTypeId: 2
      //               }
      //             ],
      //             selectOptions: [],
      //             productInfos: {
      //               MINACQ: "1",
      //               WEB_ENABLED: "1",
      //               CHECKED: "true",
      //               TIPOLOGIA: "Pezzo"
      //             },
      //             hasDetails: false
      //           },
      //           orderItemInfos: {}
      //         }
      //       ]
      //     }
      //   ]
      // }
    };
  },
  mixins: [reload, categoryMixin],
  components: {
    //OrdersListFilter,
    CategoryTitle,
    OrdersTable
  },

  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    reload() {
      let pageSize = this.showAll
        ? process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE
        : 3;
      OrderService.getOrders(
        this.pageFilter,
        pageSize,
        false,
        true,
        true,
        false
      ).then(data => {
        this.orders = this.filterData.orderTypeSelect
          ? data.orders.filter(
              order =>
                order.deliveryAddress.addressType ==
                this.filterData.orderTypeSelect
            )
          : data.orders;

        this.orderPager = data.page;

        for (var k = 0; k < this.orders.length; k++) {
          if (
            this.orders[k].orderId == 1710935 ||
            this.orders[k].orderId == 1710936
          ) {
            this.orders[k].paymentError = true;
          }
        }

        // this.orders.push(this.testOrder);
      });
      console.log("ORDERS", this.orders);
    },
    async deleteOrder(orderId) {
      await OrderService.deleteOrder(orderId);
      this.reload();
      let order = await OrderService.getOrder(orderId);
      AnalyticsService.refund(order);
    },
    async editOrder(orderId) {
      try {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.confirmOrderEdit")
        });
        if (res) {
          OrderService.editOrder(orderId).then(() => {
            this.loadCart();

            this.$router.push({
              name: "Checkout"
            });
          });
        }
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.errorOnOrderEditConfirm")
        });
        return null;
      }
    },
    async addAllToCart(orderId) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllFromOrder")
      });
      if (res) {
        await this.addProductsFromOrder(orderId);
      }
    },
    handlePageFilter(page) {
      this.$store.dispatch("category/setFilterPage", page);
      this.reload();
      this.$vuetify.goTo(0);
    },
    updateOrdersFilter(filterData) {
      this.filterData = filterData;
      this.reload();
    },
    toggleViewAll() {
      this.showAll = !this.showAll;
      this.reload();
    }
  },

  mounted() {
    this.reload();
  }
};
</script>
